/* eslint-disable no-param-reassign */
import jquery from 'jquery';
import 'select2';

import 'bootstrap/js/src/util.js';
import 'bootstrap/js/src/alert.js';
// import 'bootstrap/js/src/button.js';
import 'bootstrap/js/src/carousel.js';
import 'bootstrap/js/src/collapse.js';
// import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/scrollspy.js';
import 'bootstrap/js/src/tab.js';
import 'bootstrap/js/src/tooltip.js';
// import 'bootstrap/js/src/popover.js';

window.jQuery = window.$ = jquery;

// Custom jQuery plugins
(function ($) {
    /**
     * isInViewport
     * @param {Object} options Optional values for top and bottom offsets
     * @returns {Boolean} True if the element is in the viewport, false otherwise or
     * if the element does not exist in the document.
     */
    $.fn.isInViewport = function (options) { // This is the easiest way to have default options.
        const settings = $.extend({
            topOffset: 0,
            bottomOffset: 0,
        }, options);

        if (!$(this).length) {
            return false;
        }

        const elementTop = $(this).offset().top - settings.topOffset;
        const elementBottom = elementTop + $(this).outerHeight() + settings.bottomOffset;
        const viewportTop = $(window).scrollTop();
        const viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    /**
     * Add an opacity fading transition to an element
     */
    $.fn.loader = function () {
        const $element = $(this);
        let timeout;

        if ($element.length) {
            $element.addClass('loader');
        }

        const Fn = function () {
            this.start = function () {
                if ($element.length) {
                    $element
                        .removeClass('has-loaded')
                        .addClass('is-loading');

                    timeout = window.setTimeout(() => {
                        $element.hide();
                    }, 500);
                }
            };
            this.stop = function (options) {
                if ($element.length) {
                    window.clearTimeout(timeout);
                    $element.show();

                    window.setTimeout(() => {
                        // Optional callback between show and fade
                        if (options && options.onShow && typeof options.onShow === 'function') {
                            options.onShow();
                        }
                    }, 50);

                    window.setTimeout(() => {
                        $element
                            .removeClass('is-loading')
                            .addClass('has-loaded');
                    }, 100);
                }
            };
        };
        return new Fn();
    };

    // Fn to allow an event to fire after all images are loaded
    $.fn.imagesLoaded = function () {
        // get all the images (excluding those with no src attribute)
        const $imgs = this.find('img[src!=""]');
        // if there's no images, just return an already resolved promise
        // eslint-disable-next-line new-cap
        if (!$imgs.length) { return $.Deferred().resolve().promise(); }

        // for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
        const dfds = [];
        $imgs.each(function () {
            // eslint-disable-next-line new-cap
            const dfd = $.Deferred();
            dfds.push(dfd);
            const img = new Image();
            img.onload = function () { dfd.resolve(); };
            img.onerror = function () { dfd.resolve(); };
            img.src = this.src;
        });

        // return a master promise object which will resolve when all the deferred objects have resolved
        // IE - when all the images are loaded
        return $.when(...dfds);
    };

    // Fn to setting aria attributes more conveniently
    $.fn.aria = function (attr, val) {
        const $element = $(this);

        if ($element.length) {
            $element.attr(`aria-${attr}`, val);
        }
    };
}(jQuery));
